body {
  margin: 0;
  font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a,
button {
  cursor: pointer;
  text-decoration: none !important;
}

.mailTemplate {
  padding: 50px;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid #eee;
}
.mailTemplate-title {
  font-size: 20px;
  color: #000;
  line-height: 26px;
  margin: 0 0 20px;
}
.mailTemplate-siteLogo {
  margin-top: 40px;
}
.mailTemplate-siteLogo img {
  border: 1px solid #000;
  max-width: 400px;
  width: 100%;
  padding: 8px;
}
.mailTemplate-siteSlogan {
  font-size: 14px;
  color: #000;
  line-height: 20px;
  margin: 2px 0 0;
  max-width: 400px;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.mailTemplate-order {
  border: 1px solid #000;
  padding: 25px;
  margin-top: 85px;
}
.mailTemplate-orderTitle {
  font-size: 18px;
  color: #000;
  line-height: 22px;
  margin: 0 0 18px;
  font-weight: 500;
}
.gradientDivider {
  background-image: linear-gradient(
    to right,
    #feca0a,
    #ffd106,
    #ffd803,
    #ffdf01,
    #ffe601
  );
  width: 100%;
  display: block;
  height: 45px;
  margin: 12px 0;
}
.mailTemplate-orderTotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.mailTemplate-orderTotal h6 {
  font-size: 15px;
  color: #000;
  line-height: 22px;
  margin: 0;
  font-weight: 500;
}
.mailTemplate-orderTotal h6 span {
  margin-right: 18px;
}
.mailTemplate-orderList {
  margin-top: 50px;
  width: 100%;
  text-align: left;
}
.mailTemplate-orderList th,
.mailTemplate-orderList td {
  font-size: 14px;
  color: #000;
  line-height: 20px;
  padding: 6px 0;
}
.colorBox {
  height: 25px;
  width: 25px;
  display: block;
  border: 1px solid #000;
  border-radius: 50%;
  background-color: #feca0a;
}
.mailTemplate-footer {
  margin-top: 50px;
  padding: 0 25px;
}
.mailTemplate-footerTitle {
  font-size: 14px;
  color: #000;
  line-height: 20px;
  margin: 0 0 20px;
  font-weight: 500;
}
.mailTemplate-footer p {
  font-size: 14px;
  color: #000;
  line-height: 20px;
  margin: 6px 0;
  font-weight: 500;
}
.mailTemplate-footer p span {
  font-weight: 400;
  min-width: 155px;
  display: inline-block;
}
.loaderModule {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #ffffff69;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
.loaderModule svg {
  fill: aqua;
  height: 50px;
  width: 50px;
}

/* ORDER PAGE CSS */
.orderDetail {
  padding: 100px 25px;
}
.orderDetail-head {
  border-bottom: 3px solid #eee;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
  gap: 10px;
}
.orderDetail-head .MuiButton-root {
  padding: 6px 15px !important;
  background: #183a5e !important;
  border: 1px solid #183a5e !important;
  border-radius: 10px !important;
  color: #fff !important;
  transition: all 0.25s ease !important;
}
.orderDetail-head .MuiButton-root:last-child {
  padding: 6px 15px !important;
  background: #fff !important;
  border: 1px solid #183a5e !important;
  border-radius: 10px !important;
  color: #183a5e !important;
  transition: all 0.25s ease !important;
}
.orderListBox {
  padding: 20px;
  border-radius: 0;
  background: #fff;
}
.shoppingCardList {
  display: grid;
  grid-template-columns: 3fr 1fr;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  border-radius: 0;
  gap: 10px;
  margin: 0 0 10px;
}
.shoppingCardList:last-child {
  border: none;
}
.shoppingCardList-subTitle {
  font-weight: 600 !important;
  margin: 0 0;
  color: #000;
  font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
}
.shoppingCardList-title {
  font-weight: 500;
  margin: 0;
  color: #61514c;
  font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
}
.shoppingCardList-price {
  font-size: 15px;
  display: block;
  text-align: right;
  font-weight: 600;
  color: #172d44;
  font-family: "Poppins", sans-serif;
  display: flex;
  gap: 10px;
  align-items: center;
}
.shoppingCardList-price svg {
  color: red;
  cursor: pointer;
}
.orderListBox-title {
  display: block;
  font-weight: 600;
  margin: 0 0 20px;
  font-size: 14px;
  font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-bottom: 15px;
  line-height: 2;
  color: #be9a1c;
  position: relative;
  padding-bottom: 6px;
}
.orderListBox-title:after {
  content: "";
  display: block;
  margin: 5px auto 0 auto;
  background-color: #be9a1c;
  width: 50px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.orderListBox-list {
  height: 48vh;
  overflow-y: auto;
  padding-right: 8px;
}
.orderListBox-list::-webkit-scrollbar {
  width: 3px;
}
.orderListBox-list::-webkit-scrollbar-track {
  background: #fff;
}
.orderListBox-list::-webkit-scrollbar-thumb {
  background: #be9a1c;
}
.orderListBox-list::-webkit-scrollbar-thumb:hover {
  background: #977a0f;
}

.orderOptions-ListCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-shadow: 0 0 8px 0 #cccccc4f;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  height: 100%;
  background: #fff;
}
.orderOptions-list {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
  margin-top: 40px;
}
.orderOptions-list .MuiRadio-root span svg {
  fill: #000;
}
.orderOptions-list .MuiRadio-root.Mui-checked span svg {
  fill: #be9a1c;
}
.orderOptions-list .MuiRadio-root span svg:last-child {
  fill: #be9a1c;
}
.orderOptions-list .MuiFormControlLabel-label {
  width: 100%;
  height: 100%;
}
.orderOptions-list
  .Mui-checked
  + .MuiFormControlLabel-label
  .orderOptions-ListCard {
  border-color: #dbaa00;
}
.orderOptions-list .MuiFormControlLabel-root {
  margin-right: 0;
}
.orderOptions-ListCard-price {
  display: block;
  text-align: right;
  font-weight: 600;
  color: #dbaa00;
  font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 600;
}
.orderListBox-footer {
  border-top: 5px solid #eee;
  padding: 15px 0;
}
.orderListBox-total {
  font-size: 20px;
  line-height: 28px;
  color: #000;
  font-weight: 600;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville",
    "Georgia", serif;
}
.orderListBox-total span {
  font-size: 22px;
  display: block;
  font-weight: 600;
  color: #000;
  font-family: "Poppins", sans-serif;
}
.checkoutBtn {
  width: 100%;
  padding: 10px 15px !important;
  border: 1px solid #dbaa00 !important;
  color: #fff !important;
  transition: all 0.25s ease !important;
  border-radius: 0 !important;
  background: #dbaa00 !important;
  text-transform: uppercase !important;
  letter-spacing: 2.4px !important;
  height: 50px;
  font-size: 12px !important;
  line-height: 28px !important;
}
.checkoutBtn:hover {
  background: #fff !important;
  color: #183a5e !important;
}
.upload__image-wrapper {
  background-color: #fff;
  padding: 30px;
}
.upload__image-wrapper .image-item {
  max-width: 450px;
  margin: 0 auto;
}
.upload__image-wrapper .image-item img {
  width: 100%;
}
.upload__image-wrapper + .MuiFormControl-root {
  width: 100%;
}
.orderList {
  display: grid;
  grid-template-columns: 3fr 1fr;
  border-radius: 0;
  gap: 20px;
  margin: 0 0 12px;
}
.shoppingCardList:last-child {
  border: none;
}
.orderList div span {
  margin: 0;
  color: #61514c;
  font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-width: 320px;
  font-weight: 500;
}
.orderList p {
  margin: 0;
  color: #61514c;
  font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-width: 320px;
  text-align: right;
  font-weight: 600;
}
.checkoutCard {
  border: 1px solid #eee;
  padding: 15px;
  border-radius: 8px;
}
.checkoutCard-title {
  display: block;
  font-weight: 600 !important;
  margin: 0 0 20px !important;
  font-size: 14px !important;
  font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif !important;
  text-transform: uppercase !important;
  letter-spacing: 1.2px !important;
  margin-bottom: 15px !important;
  line-height: 2 !important;
  color: #be9a1c;
  position: relative;
  padding-bottom: 6px;
}
.checkoutCard-title::after {
  content: "";
  display: block;
  margin: 5px auto 0 auto;
  background-color: #be9a1c;
  width: 50px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.checkoutCard p {
  margin: 0;
  color: #61514c;
  font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-width: 320px;
}
.checkoutCard p.name {
  font-weight: 600 !important;
}
.smallTitle {
  font-size: 22px !important;
  line-height: 30px !important;
  color: #172d44;
  font-weight: 600 !important;
  margin: 0 0 30px !important;
}
.checkoutFooter {
  margin-top: 30px;
}
.checkoutFooter p {
  font-size: 12px;
  font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  color: #61514c;
  font-weight: 400;
  text-align: center;
}
.checkoutFooter > .MuiButtonBase-root {
  padding: 6px 15px !important;
  background: #fff !important;
  border: 1px solid #183a5e !important;
  border-radius: 10px !important;
  color: #183a5e !important;
  box-shadow: none;
  transition: all 0.25s ease !important;
  margin: 0 !important;
}
.checkoutFooter div .MuiButton-root {
  box-shadow: none;
  width: 100%;
  padding: 10px 25px !important;
  border: 1px solid #dbaa00 !important;
  color: #fff !important;
  transition: all 0.25s ease !important;
  border-radius: 0 !important;
  background: #dbaa00 !important;
  text-transform: uppercase !important;
  letter-spacing: 2.4px !important;
  height: 50px;
  font-size: 12px !important;
  line-height: 28px !important;
  min-width: 150px;
  margin: 0 !important;
  gap: 8px;
}
.checkoutFooter div .MuiButton-root svg {
  transition: all 0.5s ease;
}
.checkoutFooter div .MuiButton-root:hover svg {
  transform: translateX(25px);
}
.checkoutFooter div .MuiButton-root.backBtn:hover svg {
  transform: rotate(180deg) translateX(25px) !important;
}
.checkoutFooter div .MuiButton-root:last-child {
  box-shadow: none;
  width: 100%;
  padding: 10px 22px !important;
  border: 1px solid #dbaa00 !important;
  color: #fff !important;
  transition: all 0.25s ease !important;
  border-radius: 0 !important;
  background: #dbaa00 !important;
  text-transform: uppercase !important;
  letter-spacing: 2.4px !important;
  height: 50px;
  font-size: 12px !important;
  line-height: 28px !important;
  min-width: 155px;
  gap: 8px;
}
.checkoutTitle {
  line-height: 60px !important;
  font-weight: 600 !important;
  margin: 15px 0 !important;
  font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville",
    "Georgia", serif !important;
  color: #302724;
  font-size: 50px !important;
  margin: 0 !important;
}

.stepperTitle {
  font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville",
    "Georgia", serif !important;
  color: #302724;
  font-size: 28px !important;
  font-weight: 600 !important;
  margin: 0 0 28px !important;
}
.checkoutStepper {
  padding: 20px 0;
  justify-content: center;
}
.checkoutStepper .MuiStepLabel-root.MuiStepLabel-horizontal {
  flex-direction: column;
  width: 125px;
  text-align: center;
}
.checkoutStepper .MuiStepConnector-root.MuiStepConnector-horizontal {
  flex: 50px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.checkoutStepper .MuiStepLabel-label {
  font-size: 12px;
  font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  color: #61514c;
  font-weight: 500;
}
.checkoutStepper .MuiStepLabel-label.Mui-active {
  color: #dbaa00;
}
.checkoutStepper .MuiStepConnector-line {
  border-top-width: 2px;
  width: 140px;
  position: absolute;
  top: -15px;
}
.checkoutStepper .MuiStepLabel-iconContainer {
  margin-bottom: 6px;
  padding: 0 !important;
}
.checkoutStepper .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  height: 30px;
  width: 30px;
}
.checkoutStepper .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-active,
.checkoutStepper .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-completed {
  color: #dbaa00;
}
.checkboxCard {
  border: none !important;
}
.checkboxCard .MuiFormControl-root {
  background-color: #fff;
  width: 100%;
}
.checkboxCard .MuiFormControl-root .MuiFormLabel-root {
  font-size: 14px;
  font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  color: #61514c;
  font-weight: 400;
  line-height: 25px;
}
.checkboxCard .MuiFormControl-root input {
  font-size: 14px;
  font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  color: #61514c;
  font-weight: 400;
}
.checkboxCard .MuiCheckbox-root svg {
  color: #dbaa00;
}
.checkboxCard .MuiFormControlLabel-label {
  font-size: 14px;
  font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  color: #61514c;
  font-weight: 400;
  line-height: 30px;
  font-weight: 600;
}
.orderOptions-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  position: relative;
  /* margin-top: 50px; */
}
.orderOptions-header:after {
  content: "";
  display: block;
  margin: 5px auto 0 auto;
  background-color: #be9a1c;
  width: 50px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.orderOptions-header h1 {
  font-size: 26px;
  line-height: 32px;
  display: block;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;
  font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville",
    "Georgia", serif;
  color: #302724;
}
.orderOptions-header button {
  border: 2px solid #be9a1c;
  background: transparent;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-sizing: border-box;
}
.orderOptions-header button svg {
  height: 18px;
  width: 18px;
  fill: #be9a1c;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #141313;
}
.title {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #141313;
}

.successMessage {
  min-height: 62vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.successMessage img {
  height: 200px;
  margin: 0 0 25px;
}
.successMessage__title {
  font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville",
    "Georgia", serif !important;
  color: #302724;
  font-weight: 500 !important;
  margin: 0 0 10px !important;
  font-size: 44px !important;
}
.successMessage__text {
  color: #61514c;
  font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  max-width: 550px;
  font-weight: 400 !important;
  margin: 8px 0 0px !important;
}
.orderMainTitle {
  text-align: center;
  display: block;
  margin: 40px 0 8px;
  font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  letter-spacing: 1.2px;
  line-height: 1;
  color: #be9a1c;
  font-weight: 700;
  font-size: 17px;
}
