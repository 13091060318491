// Variables
$bar-color: #000;
$ball-color: #dbaa00;

.blogSection {
    background: #f5f5f5;
    padding: 0 15px;
    margin: 80px 0;

    &__heading {
        text-align: center;
        margin-bottom: 55px;
    }

    &__title {
        color: #be9a1c;
        font-size: 12px;
        font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 2;
        position: relative;

        &:after {
            content: '';
            display: block;
            margin: 5px auto 0 auto;
            background-color: #be9a1c;
            width: 30px;
            height: 1px;
        }
    }

    &__subTitle {
        line-height: 1.1;
        font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
        color: #302724;
        font-weight: 400;
        margin: 0;
        font-size: 50px;
    }

    &__footer {
        margin-top: 50px;
    }
}

.blogCard {
    &__img {
        height: 400px;
        width: 100%;
        overflow: hidden;
        position: relative;
        display: block;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        svg {
            position: absolute;
            transition: all 0.5s ease;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            opacity: 0;
            height: 40px;
            width: 40px;
            fill: #fff;
            border: 1px solid #fff;
            padding: 6px;
        }

        &::after {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.4);
            display: block;
            opacity: 0;
            transition: all 0.5s ease;
        }

        &:hover {
            &::after {
                opacity: 100%;
            }

            svg {
                opacity: 100%;
            }
        }
    }

    &__info {
        color: #a6a3a3;
        display: flex;
        font-size: 12px;
        letter-spacing: 1.2px;
        font-weight: 500;
        margin-bottom: 10px;
        gap: 10px;
    }

    &__category {
        list-style-type: disc;
        display: list-item;
        margin-left: 12px;

        span {
            color: #be9a1c;
            margin: 0 2px;
        }
    }

    &__content {
        margin-top: 25px;

        p {
            font-size: 13px;
            color: #61514c;
            font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
        }
    }

    &__title {
        font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
        color: #302724;
        font-size: 28px;
        margin: 0 0 10px;
        font-weight: 500;
        line-height: 35px;

        a {
            color: #302724;
            transition: all 0.5s ease;

            &:hover {
                color: #be9a1c;
            }
        }
    }

}

.roundedBtn {
    background-color: transparent;
    border: 1px solid #be9a1c;
    border-radius: 50%;
    color: #be9a1c;
    width: 220px;
    height: 220px;
    padding: 30px 9px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin: 0 auto;

    span {
        position: relative;
        padding-bottom: 15px;
    }

    svg {
        position: absolute;
        left: 0;
        bottom: -15px;
        transition: all 0.5s ease;
        width: 32px;
        height: 32px;
    }

    &:hover {
        svg {
            left: 100%;
            transform: translate(-100%, 0);
        }
    }
}

.contentBlock {
    padding: 0 15px;
    margin: 120px 0;
    text-align: center;
    min-height: 30vh;
    display: flex;
    align-items: center;

    blockquote {
        font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
        color: #302724;
        font-weight: 400;
        font-size: 55px;
        line-height: 70px;
        max-width: 800px;
        margin: 0 auto;
    }
}

.contentMediaBlock {
    padding: 0 15px;
    margin: 120px 0;

    &__img {
        position: relative;

        .largeImage {
            width: 70%;
            margin: 0 0 100px 30%;
        }

        .smallImage {
            position: absolute;
            left: 100px;
            width: 100%;
            max-width: 255px;
            bottom: 0;
        }

        .roundedBtn {
            position: absolute;
            top: 30%;
        }
    }

    &__subtitle {
        color: #be9a1c;
        font-size: 12px;
        font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 2;
        position: relative;
        margin: 0 0 15px;
    }

    &__title {
        line-height: 1.1;
        font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
        color: #302724;
        font-weight: 400;
        margin: 0 0 40px;
        font-size: 50px;
    }

    &__text {
        p {
            color: #61514c;
            font: 16px/1.8 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
            max-width: 370px;
        }
    }
}

.loader {

    position: relative;
    width: 75px;
    height: 100px;

    &__bar {
        position: absolute;
        bottom: 0;
        width: 10px;
        height: 50%;
        background: $bar-color;
        transform-origin: center bottom;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, .2);

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                left: ($i - 1) * 15px;
                transform: scale(1, $i*.2);
                animation: barUp#{$i} 4s infinite;
            }
        }

    }

    &__ball {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 13px;
        height: 13px;
        background: $ball-color;
        border-radius: 50%;
        animation: ball 4s infinite;
    }
}

@keyframes ball {
    0% {
        transform: translate(0, 0);
    }

    5% {
        transform: translate(8px, -14px);
    }

    10% {
        transform: translate(15px, -10px)
    }

    17% {
        transform: translate(23px, -24px)
    }

    20% {
        transform: translate(30px, -20px)
    }

    27% {
        transform: translate(38px, -34px)
    }

    30% {
        transform: translate(45px, -30px)
    }

    37% {
        transform: translate(53px, -44px)
    }

    40% {
        transform: translate(60px, -40px)
    }

    50% {
        transform: translate(60px, 0)
    }

    57% {
        transform: translate(53px, -14px)
    }

    60% {
        transform: translate(45px, -10px)
    }

    67% {
        transform: translate(37px, -24px)
    }

    70% {
        transform: translate(30px, -20px)
    }

    77% {
        transform: translate(22px, -34px)
    }

    80% {
        transform: translate(15px, -30px)
    }

    87% {
        transform: translate(7px, -44px)
    }

    90% {
        transform: translate(0, -40px)
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes barUp1 {
    0% {
        transform: scale(1, .2);
    }

    40% {
        transform: scale(1, .2);
    }

    50% {
        transform: scale(1, 1);
    }

    90% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(1, .2);
    }
}

@keyframes barUp2 {
    0% {
        transform: scale(1, .4);
    }

    40% {
        transform: scale(1, .4);
    }

    50% {
        transform: scale(1, .8);
    }

    90% {
        transform: scale(1, .8);
    }

    100% {
        transform: scale(1, .4);
    }
}

@keyframes barUp3 {
    0% {
        transform: scale(1, .6);
    }

    100% {
        transform: scale(1, .6);
    }
}

@keyframes barUp4 {
    0% {
        transform: scale(1, .8);
    }

    40% {
        transform: scale(1, .8);
    }

    50% {
        transform: scale(1, .4);
    }

    90% {
        transform: scale(1, .4);
    }

    100% {
        transform: scale(1, .8);
    }
}

@keyframes barUp5 {
    0% {
        transform: scale(1, 1);
    }

    40% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1, .2);
    }

    90% {
        transform: scale(1, .2);
    }

    100% {
        transform: scale(1, 1);
    }
}