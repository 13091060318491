.siteHeader {
    position: sticky !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    background-color: #ffffff !important;
    padding: 10px 15px !important;
    color: #000;
    box-shadow: none !important;
    border: 1px solid #ddd;

    &__inner {
        max-width: 1800px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    &__logo {
        img {
            width: 150px;
        }
    }

    &__navbar {
        display: flex;
        align-items: center;
        gap: 40px;
    }

    &__userBlock {
        display: flex;
        align-items: center;
        gap: 20px;

        button {
            border-radius: 0;
            background: #dbaa00;
            font-family: inherit;
            text-transform: uppercase;
            letter-spacing: 2.4px;
            color: #fff;
        }
    }

    &__userName {
        font-size: 14px;
        color: #000;
        margin: 0;
    }

    &__menu {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;

        li {
            position: relative;
        }

        .nav-link {
            font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
            color: #000;
            text-transform: capitalize;
            letter-spacing: 0.65px;
            position: relative;
            font-weight: 500;

            &.nav-link--dropdown::after {
                content: "";
                height: 8px;
                width: 8px;
                border-left: 2px solid #888;
                border-bottom: 2px solid #888;
                display: inline-block;
                background: transparent;
                transform: rotate(-45deg);
                margin-top: -4px;
                margin-left: 10px;
            }

            &:after {
                content: '';
                height: 3px;
                width: 0%;
                background: #dbaa00;
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: all 0.5s ease;
            }

            &:hover {
                &::after {
                    width: 100%;
                }
            }
        }
    }

    .dropdown-list {
        position: absolute;
        background: #fff;
        border: 1px solid #eee;
        padding: 0;
        margin: 0;
        min-width: 150px;
        top: 100%;
        left: 0;
    }
}

.langBox {
    border: 1px solid #dbaa00;
    box-shadow: none;
    overflow: hidden;

    button {
        border-radius: 0;
        background: #fff;
        font-family: inherit;
        text-transform: uppercase;
        letter-spacing: 2.4px;
        color: #dbaa00;
        border: none !important;
        box-shadow: none !important;
        height: 40px;
        min-width: 75px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 15px;

        &:hover {
            background: #fff;
        }
    }
}

.langDropdown {
    border-top: 2px solid #dbaa00;
    margin-top: 10px !important;
    box-shadow: none;

    .MuiPaper-root {
        box-shadow: none;
    }
}