.dataListTable {
    padding: 80px 10px;

    &__title {
        font-family: "Cormorant Garamond", "Times New Roman", "Times", "Baskerville", "Georgia", serif;
        color: #302724;
        font-weight: 500;
        font-size: 44px;
        margin: 0 0 22px;
        text-align: center;
    }

    .MuiPaper-root {
        padding: 15px;
        box-shadow: none;
    }

    table thead {
        border-bottom: 2px solid #dbaa00;
    }

    table thead th {
        font-size: 14px;
        font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        font-weight: 600;
        color: #be9a1c;
        text-align: left;
        padding: 12px 20px;
    }

    table td {
        color: #61514c;
        font: 14px/1.6 "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
        padding: 12px 20px;
        text-align: left;
    }

}